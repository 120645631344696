<script>
    import Router, { link } from "svelte-spa-router";
    import { routes } from "./routes.js";

    import { pageName } from "./stores.js"

    $: {
        document.title = $pageName ? `${$pageName} - clue.social` : "clue.social"
    }
</script>

<Router {routes}/>

<style>
    @font-face {
        font-family: "Questrial";
        src: url("../assets/fonts/questrial/Questrial-Regular.ttf");
    }

    :global(*) {
        box-sizing: border-box;
    }

    :global(html) {
        height: 100%;
    }

    :global(body) {
        min-height: 100%;
        margin: 0;
        background-color: #0d44db;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: -apple-system, BlinkMacSystemFont ,"Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    }
</style>
