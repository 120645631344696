<script>
    import { pageName } from "../stores.js"

    pageName.set("");
</script>

<div class="logo">
    <img alt="clue.social" src="assets/images/logo/logo.png" />
    <span>clue.social</span>
</div>

<style>
    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .logo > img {
        height: 46px;
        margin-right: 7px;
    }

    .logo > span {
        font-family: "Questrial", sans-serif;
        font-size: 46px;
        font-weight: 600;
    }
</style>