<script>
    import { link } from "svelte-spa-router";
    import { pageName } from "../stores.js"

    pageName.set("Page not found");
</script>

<span>Page not found</span>
<a href="/" use:link>Home →</a>

<style>
    span {
        font-size: 28px;
        font-weight: 600;
        padding-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-size: 16px;
    }

    a:hover {
        opacity: 0.85;
    }
</style>